<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="10">
                <div class="TitleBocks">
                    <div>Создать Формы</div>
                    <span>Настройка  Формы для ввода информация</span>
                </div>
            </el-col>
            <el-col :span="12" class="text-left">
                <el-button type="success">Сохранить</el-button>
                <el-button type="danger">Удалить</el-button>
                <el-button>Печать</el-button>
                <el-button><i class="el-icon-printer"></i>Печать</el-button>
            </el-col>
        </el-row>
        
        <el-tabs v-model="activeName" @tab-click="handleClick" class="createForms">

            <el-tab-pane class="one-tabs"   label="Информация"  name="information">
                
                <el-form ref="form" :model="form">

                    <el-row :gutter="30">
                        <el-col :span="16">
                            <el-form-item label="Наименование">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>
                            <el-form-item label="Описание">
                                <el-input v-model="form.description"></el-input>
                            </el-form-item>
                            <el-form-item label="Печатная форма">
                                <el-select v-model="form.printingForm" placeholder="--не указан--">
                                <el-option label="Zone one" value="shanghai"></el-option>
                                <el-option label="Zone two" value="beijing"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="Тип формы">
                                <el-select v-model="form.typeFormi" placeholder="--не указан--">
                                    <el-option label="Zone one" value="shanghai"></el-option>
                                    <el-option label="Zone two" value="beijing"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Отделение (разрешено для указанных)">
                                <el-select v-model="form.branch" placeholder="--не указан--">
                                    <el-option label="Zone one" value="shanghai"></el-option>
                                    <el-option label="Zone two" value="beijing"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Специализация (разрешено для указанных)">
                                <el-select v-model="form.specialization" placeholder="--не указан--">
                                    <el-option label="Zone one" value="shanghai"></el-option>
                                    <el-option label="Zone two" value="beijing"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Разметка">
                                <el-select v-model="form.markup" placeholder="--не указан--">
                                    <el-option label="Zone one" value="shanghai"></el-option>
                                    <el-option label="Zone two" value="beijing"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>

            </el-tab-pane>

            <el-tab-pane class="two-tabs"   label="Редактор"     name="editor">
                 <el-form ref="form" :model="form">

                    <el-row :gutter="20">
                        <el-col :span="16">
                            <div class="two-tabs-title">Конструктор</div>
                            <div class="constructor_block">

                                <div class="not_a mb-4">
                                    <div class="xi">x</div>
                                    <span>Перетащите один из доступных шаблонов из палитры на эту панель.</span>
                                </div>


                                <div class="formaDummentCalab mt-5">

                                    <div class="time__From form__border mb-5">
                                        <div class="forma__doc"> 
                                            <el-form-item label="Текст">
                                                <el-input v-model="form.name"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="">
                                            <el-checkbox class="butPos0" v-model="form.checkedw2"></el-checkbox>

                                            <button class="btn butPos1"  data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                                <i class="el-icon-arrow-down"></i>
                                            </button>
                                            <button class="btn butPos2" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                                <i class="el-icon-arrow-up"></i>
                                            </button>
                                        </div>
                                        <div class="collapse" id="collapseExample">
                                            <el-tabs type="border-card">
                                                <el-tab-pane label="Свойства">
                                                    <el-form-item label="Name">
                                                        <el-input v-model="form.nameTab1"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Отображаемое">
                                                        <el-input v-model="form.displayedTab1"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Текст заполнителя">
                                                        <el-input v-model="form.placeholderTab1"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Подсказка">
                                                        <el-input v-model="form.hintTab1"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Начальное">
                                                        <el-input v-model="form.initialTab1"></el-input>
                                                    </el-form-item>
                                                </el-tab-pane>
                                                <el-tab-pane label="Валидация">
                                                    <el-form-item label="Минимальная длина">
                                                        <el-input v-model="form.minimumlengthTab2"></el-input>
                                                    </el-form-item>
                                                    <!-- <el-form-item label="Message">
                                                        <el-input v-model="form.messageTab2" placeholder="Optional message"></el-input>
                                                    </el-form-item> -->
                                                    <el-form-item label="Минимальная длина">
                                                        <el-input v-model="form.minimumlengthTab2"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Правило">
                                                        <el-select v-model="form.rutTab2">
                                                        <el-option label="Zone one" value="shanghai"></el-option>
                                                        <el-option label="Zone two" value="beijing"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                    <el-form-item label="Обязательный ">
                                                        <el-switch v-model="form.switchTab2"></el-switch>
                                                    </el-form-item>
                                                    <!-- <el-form-item label="Message">
                                                        <el-input v-model="form.messageTab2" placeholder="Optional message"></el-input>
                                                    </el-form-item> -->
                                                </el-tab-pane>
                                                <el-tab-pane label="Отладка">Отладка</el-tab-pane>
                                            </el-tabs>
                                        </div>
                                    </div>

                                    <div class="time__From form__border mb-5">
                                        <div class="forma__doc"> 
                                            <el-form-item label="Текст">
                                                <el-input type="textarea" v-model="form.name"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="">
                                            <el-checkbox class="butPos0" v-model="form.checkedw"></el-checkbox>

                                            <button class="btn butPos1"  data-toggle="collapse" data-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2">
                                                <i class="el-icon-arrow-down"></i>
                                            </button>
                                            <button class="btn butPos2" data-toggle="collapse" data-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2">
                                                <i class="el-icon-arrow-up"></i>
                                            </button>
                                            
                                        </div>
                                        <div class="collapse" id="collapseExample2">
                                            <el-tabs type="border-card">
                                                <el-tab-pane label="Свойства">
                                                    <el-form-item label="Name">
                                                        <el-input v-model="form.nameTab1"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Отображаемое">
                                                        <el-input v-model="form.displayedTab1"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Текст заполнителя">
                                                        <el-input v-model="form.placeholderTab1"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Подсказка">
                                                        <el-input v-model="form.hintTab1"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Начальное">
                                                        <el-input v-model="form.initialTab1"></el-input>
                                                    </el-form-item>
                                                </el-tab-pane>
                                                <el-tab-pane label="Валидация">
                                                    <el-form-item label="Минимальная длина">
                                                        <el-input v-model="form.minimumlengthTab2"></el-input>
                                                    </el-form-item>
                                                    <!-- <el-form-item label="Message">
                                                        <el-input v-model="form.messageTab2" placeholder="Optional message"></el-input>
                                                    </el-form-item> -->
                                                    <el-form-item label="Минимальная длина">
                                                        <el-input v-model="form.minimumlengthTab2"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Правило">
                                                        <el-select v-model="form.rutTab2">
                                                        <el-option label="Zone one" value="shanghai"></el-option>
                                                        <el-option label="Zone two" value="beijing"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                    <el-form-item label="Обязательный ">
                                                        <el-switch v-model="form.switchTab2"></el-switch>
                                                    </el-form-item>
                                                    <!-- <el-form-item label="Message">
                                                        <el-input v-model="form.messageTab2" placeholder="Optional message"></el-input>
                                                    </el-form-item> -->
                                                </el-tab-pane>
                                                <el-tab-pane label="Отладка">Отладка</el-tab-pane>
                                            </el-tabs>
                                        </div>
                                    </div>

                                    <div class="time__From form__border mb-5">

                                        <div class="">
                                            <el-checkbox class="butPos0" v-model="form.checkedw"></el-checkbox>

                                            <button class="btn butPos1"  data-toggle="collapse" data-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample3">
                                                <i class="el-icon-arrow-down"></i>
                                            </button>
                                            <button class="btn butPos2" data-toggle="collapse" data-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample3">
                                                <i class="el-icon-arrow-up"></i>
                                            </button>
                                            
                                        </div>
                                        
                                        <div class="forma__doc"> 
                                            <el-form-item label="Список флала выбора">
                                                <el-select v-model="form.region" placeholder="please select your zone">
                                                <el-option label="Zone one" value="shanghai"></el-option>
                                                <el-option label="Zone two" value="beijing"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>

                                        <div class="collapse" id="collapseExample3">
                                            <el-tabs type="border-card">
                                                <el-tab-pane label="Свойства">
                                                    <el-form-item label="Name">
                                                        <el-input v-model="form.nameTab1"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Отображаемое">
                                                        <el-input v-model="form.displayedTab1"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Подсказка">
                                                        <el-input v-model="form.hintTab1"></el-input>
                                                    </el-form-item>
                                                </el-tab-pane>
                                                <el-tab-pane label="Opitions">
                                                    <div class="tab2_title">
                                                        <span>Value</span>
                                                        <span>Value</span>
                                                        <span class="el-icon-circle-plus-outline"></span>
                                                    </div>
                                                    <div class="add_Opitins">
                                                        <el-radio v-model="form.radio" label=""></el-radio>
                                                        <el-input class="ml-1 mr-1"  v-model="form.input"></el-input>
                                                        <el-input class="ml-1 mr-1"  v-model="form.input"></el-input>
                                                        <el-button type="danger" icon="el-icon-delete" circle></el-button>
                                                    </div>
                                                </el-tab-pane>
                                                <el-tab-pane label="Отладка">Отладка</el-tab-pane>
                                            </el-tabs>
                                        </div>
                                    </div>    
                                   
                                </div>
                               

                               

                            </div>
                        </el-col>
                        <!-- col end-16 -->
                        <el-col :span="8">
                            <div class="two-tabs-title">Элементы</div>

                            <div class="add-block-doc add-text-blocks">
                                <i class="el-icon-circle-plus-outline positionl"></i>
                                <el-form-item label="Текст">
                                    <el-input v-model="form.addNewSectionMin"></el-input>
                                </el-form-item>
                            </div>

                            <div class="add-block-doc add-text-blocks">
                                <i class="el-icon-circle-plus-outline positionl"></i>
                                <el-form-item label="Число">
                                    <el-input v-model="form.addNewSectionNumber"></el-input>
                                </el-form-item>
                            </div>

                            <div class="add-block-doc add-text-blocks">
                                <i class="el-icon-circle-plus-outline positionl"></i>
                                <el-form-item label="Текст">
                                    <el-input type="textarea" v-model="form.addNewSectionBig"></el-input>
                                </el-form-item>
                            </div>

                            <div class="add-block-doc add-text-blocks">
                                <i class="el-icon-circle-plus-outline positionl"></i>
                                <el-checkbox v-model="form.checked__Flag">Флаг</el-checkbox>
                            </div>

                            <div class="add-block-doc add-text-blocks">
                                <i class="el-icon-circle-plus-outline positionl"></i>
                                <div class="el-form-item__label w-100 text-left mb-3">Список флажок</div>
                                <el-checkbox v-model="form.option_1" class="w-100">Опция 1</el-checkbox>
                                <el-checkbox v-model="form.option_2" class="w-100">Опция 2</el-checkbox>
                                <el-checkbox v-model="form.option_3" class="w-100">Опция 2</el-checkbox>
                            </div>

                            <div class="add-block-doc add-text-blocks">
                                <i class="el-icon-circle-plus-outline positionl"></i>
                                <div class="el-form-item__label w-100 text-left mb-3">Список  флала выбора  </div>
                                <el-radio-group v-model="form.option_Radio">
                                    <el-radio :label="3" class="w-100">Опция 1</el-radio>
                                    <el-radio :label="6" class="w-100">Опция 2</el-radio>
                                    <el-radio :label="9" class="w-100">Опция 3</el-radio>
                                </el-radio-group>
                            </div>

                            <div class="add-block-doc add-text-blocks">
                                <i class="el-icon-circle-plus-outline positionl"></i>
                                <div class="el-form-item__label w-100 text-left mb-3">Список  флала выбора  </div>
                                <el-select v-model="form.picklist" class="w-100 ">
                                    <el-option label="Zone one" value="shanghai"></el-option>
                                    <el-option label="Zone two" value="beijing"></el-option>
                                </el-select>
                            </div>

                        </el-col>
                        <!-- col end-8 -->
                    </el-row>

                </el-form>
            </el-tab-pane>
            
            <el-tab-pane class="three-tabs" label="Просмотр"    name="view">
                
                <el-form ref="form" :model="form">  
                    <el-form-item label="Текст">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="Текст">
                        <el-input v-model="form.description"  type="textarea" :rows="3"></el-input>
                    </el-form-item>
                    <el-form-item label="Список флала выбора">
                        <el-select v-model="form.printingForm" placeholder="--не указан--" class="w-100">
                            <el-option label="Zone one" value="shanghai"></el-option>
                            <el-option label="Zone two" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>   
                </el-form>

            </el-tab-pane>
        </el-tabs>

    </div>
</template>
<script>
  export default {
    name: 'createForm',
    data() {
        return {
            activeName: 'information',
            form:{
                name:'',
                description: '',
                printingForm: '',
                typeFormi: '',
                branch: '',
                specialization: '',
                markup: '',

                addNewSectionMin: '',
                addNewSectionNumber: '',
                addNewSectionBig: '',
                checked__Flag: false,
                option_1: false,
                option_2: false,
                option_3: false,
                option_Radio: 3,
                picklist:'',

                nameTab1: '',
                displayedTab1:'',
                placeholderTab1:'',
                hintTab1:'',
                initialTab1:'',
                minimumlengthTab2:'',
                messageTab2:'',
                rutTab2:'',
                switchTab2: false,
               
               
            }
        }
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
    }
    
  }
</script>
<style lang="scss">
.createForms{
    // background-color: #fff;
    margin-top: 30px;
    padding: 10px 0px;
    .el-tabs__nav{
        margin-left: 20px;
    }
    .el-tabs__content {
        padding: 0px 20px;
    }
    .el-tab-pane{
        margin-top: 30px;
    }
}
.createForms{
    .one-tabs{
        .el-select{
            width: 100%;
        }
    }
}
.add-block-doc{
    border: 1px solid #dcdfe6;
    padding: 15px;
    padding-top: 18px;
    border-radius: 5px;
    margin: 20px 0px;
    position: relative;
    background-color: #fff;
    .positionl{
        position: absolute;
        right: 10px;
        top: -15px;
        font-size: 28px;
        background-color: #fff;
        color: #dcdfe6;
        border-radius: 100%;
        cursor: pointer;
   }
    .el-form-item{
        margin-bottom: 0px;
    }
}
.constructor_block{
    border: 1px solid #dcdfe6;
    padding: 15px;
    padding-top: 18px;
    border-radius: 5px;
    margin: 20px 0px;
    position: relative;
}
.not_a{
    padding: 20px;
    background-color: #1f74a83d;
    font-size: 18px;
    text-align: center;
    .xi{
        font-size: 100px;
    }
}
.form__border{
    border: 1px solid #dcdfe6;
    padding: 15px;
}
.tab2_title{
    font-size: 18px;
    display: flex;
    justify-content: space-between;
}
.tab2_title> .el-icon-circle-plus-outline{
    font-size: 24px;
}
.add_Opitins{
    display: flex;
    align-items: center;
    margin-top: 10px;
    .el-radio{
        margin: 0px;
    }
}
</style>